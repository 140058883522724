import { useEffect, useState } from "react";
import useRequest from './useRequest';

const useAuthor = () => {
  const [authors, setAuthors] = useState([])
  const [loading, setLoading] = useState(false)
  const request = useRequest();
  const fetchAuthors = async () => {
    const { data: authors } = await request.get('/authors?size=9999');
    const authorsMap = authors.map(author => {
      return { value: author.id, label: author.name }
    })
    setAuthors(authorsMap)
    setLoading(false)
  }

  const addAuthor = async (authorName) => {
    try {
      const {data} = await request.post('/authors', { name: authorName });
      const newAuthors = [...authors, { value: data.id, label: authorName }]
      setAuthors(newAuthors);
      return data;
    } catch (exception) {
    }

  }
  useEffect(() => {
    setLoading(true)
    fetchAuthors()
  }, [])

  return [{ authors, loading, fetchAuthors, addAuthor }]
}

export default useAuthor;
