import React, { useEffect, useState } from 'react'
import useSummary from '../..//hooks/useSummary'
import LabelComponent from '../Form/LabelComponent'
import useAuthor from '../../hooks/useAuthor'
import AutorInput from '../Form/AutorInput'
import TagInput from '../Form/TagInput'
import DownloadButton from './DownloadButton'
import PreviewImage from './PreviewImage'
import MediaAttribute from './MediaAttribute'
import './styles.css'
import useTags from '../../hooks/useTags'
import Summary from './Summary'
import IdLabel from './IdLabel'
import { useMedia } from '../../hooks/useMedia'
import { DisappearedLoading } from 'react-loadingg'
import ErrorLabelComponent from './ErrorLabel'
import useLocation from '../../hooks/useLocation'
import MediaUrl from './MediaUrl'
import useRol from '../../hooks/useRol'

const MediaForm = ({ media, navigateNextMedia, navigatePreviousMedia, saveMedia }) => {
  const [{ tags, fetchTags }] = useTags()

  const [{ authors, fetchAuthors }] = useAuthor()
  const [{ locations, fetchRegions }] = useLocation()
  const [{ roles, fetchRoles }] = useRol()
  const [loading, setLoading] = useState(true)
  const [disabledMessageError, setDisabledMessageError] = useState(false)
  const mediaHook = useMedia()
  const [currentMedia, setCurrentMedia] = useState(undefined)
  const [currentTypeMedia, setCurrentTypeMedia] = useState(undefined)

  const chromeAgent = navigator.userAgent.indexOf('Chrome') > -1
  let safariAgent = navigator.userAgent.indexOf('Safari') > -1
  if ((chromeAgent) && (safariAgent)) safariAgent = false

  const typeValues = [
    { value: 'video', label: 'Video', type: 'video' },
    { value: 'footage', label: 'Footage', type: 'video' },
    { value: 'photo', label: 'Foto', type: 'image' },
  ]

  const handleInputChange = event => {
    const { name, value, type, checked } = event.target
    const identifier = calculateIdentifier(mediaHook.media.identifier,
      name === 'type' ? value : currentMedia.type)
    setCurrentMedia({
      ...currentMedia,
      [name]: type === 'checkbox' ? checked : value,
      identifier,
    })
  }

  const handleTagsChange = (tags) => {
    setCurrentMedia(
      { ...currentMedia, tags: tags?.map(t => t.value).join(',') })
  }
  const handleAuthorChange = (author) => {
    setCurrentMedia(
      { ...currentMedia, author: { name: author.label, id: author.value } })
  }

  const calculateIdentifier = (identifier, type) => {
    let prefix
    switch (type) {
      case 'video':
        prefix = 'VID_'
        break
      case 'footage':
        prefix = 'FOOT_'
        break
      case 'photo':
        prefix = 'IMG_'
        break
      default:
        prefix = ''
    }
    return prefix + identifier.toString().padStart(7, '0')
  }

  const changeTypeMedia = (media) => {
    let typeFilter = typeValues.filter(type => type.value === media.type)

    if (typeFilter[0])
      setCurrentTypeMedia(typeFilter[0].type)
  }

  const validateValuesMedia = (media) => {
    if (!media.title)
      return false

    if (media.title && media.title.trim().length === 0)
      return false

    if (!media.author)
      return false

    return true
  }

  const initializeForm = media => {
    changeTypeMedia(media)
    setLoading(false)
    Promise.all([
      fetchTags(),
      fetchRegions(),
      fetchRoles(),
      fetchAuthors(),
    ]).then()
  }

  useEffect(() => {
    setCurrentMedia(media)
    initializeForm(media)
  }, [media])

  const handleFormSubmit = event => {
    event.preventDefault()
    setDisabledMessageError(false)

    if (!validateValuesMedia(currentMedia)) {
      setDisabledMessageError(true)
      return
    }

    setLoading(true)
    setDisabledMessageError(false)
    saveMedia(currentMedia)
  }

  const eventNextMedia = event => {
    setDisabledMessageError(false)
    navigateNextMedia(event)
  }

  const eventPreviousMedia = event => {
    setDisabledMessageError(false)
    navigatePreviousMedia(event)
  }

  if (loading) {
    return (
      <div>
        <DisappearedLoading />
      </div>
    )
  }

  const renderVideo = () => {
    if (safariAgent && currentMedia.files.highQuality &&
      currentMedia.files.highQuality.toLowerCase().indexOf('.mov') > -1)
      return (
        <source src={currentMedia.files.highQuality} type='video/quicktime' />)

    return (<source
      src={currentMedia.files.lowQuality || currentMedia.files.highQuality}
      type='video/mp4' />)
  }

  const renderMedia = () => {
    if (currentMedia.type !== 'photo') {
      return (<video controls className="w-full">{renderVideo()}</video>)
    }
    return (
      <img alt={mediaHook.id} className='w-100'
        src={currentMedia.files.highQuality}
        loading={'lazy'} />)
  }
  return (
    currentMedia && <div className='flex flex-wrap w-full relative mb-16'>
      <section className='w-1/4 xl:w-3/5 xl:p-4'>
        <section className='w-full text-xs'>
          {renderMedia()}
          <div
            className='flex flex-col md:flex-row flex-wrap justify-between mt-2 p-2 items-center bg-white rounded-lg'>
            <IdLabel id={currentMedia.id} />
            <MediaUrl highQuality={currentMedia.files.highQuality} />
            <div className='flex justify-center items-center mt-2 md:mt-0'>
              <DownloadButton mediaFile={currentMedia.files.highQuality} />
            </div>
          </div>

          <div
            className='flex flex-col w-full justify-between mt-8 items-center'>
            <div
              className='flex flex-col md:flex-row w-full rounded-lg -my-2 align-center justify-center align-middle'>
              <div className='relative w-full xl:w-2/3 xl:m-2'>
                <PreviewImage image={currentMedia.files.thumbnail} />
              </div>
              <div className='flex items-center md:w-1/3'>
                <div
                  className='flex flex-row flex-wrap justify-start items-center md:pl-2'>
                  <MediaAttribute name={'Peso'} attr={currentMedia.size} />
                  <MediaAttribute name={'Extensión'}
                    attr={currentMedia.extension} />
                  <MediaAttribute name={'Dimensión'}
                    attr={`${currentMedia.resolution.width}X${currentMedia.resolution.height}`} />
                  {currentMedia.type !== 'photo' &&
                    <MediaAttribute name={'Duración'}
                      attr={`${currentMedia.metaData.duration} seg`} />}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className='w-1/2 mt-8 lg:w-4/5 lg:mx-auto xl:mt-0 xl:w-2/5'>
        <form onSubmit={handleFormSubmit}
          className='mt-4 p-4 bg-white rounded-lg'>
          <div role="alert" className='mb-4' hidden={!disabledMessageError}>
            <div
              className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
              Error.
            </div>
            <div
              className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
              <p>Existe errores en el formulario. Verifica los campos
              obligatorios marcados
                con <strong>(*)</strong>.</p>
            </div>
          </div>

          <div className='flex flex-col md:flex-row'>
            <div className="w-full px-3 mb-6 md:mb-0">
              <LabelComponent label={'Tipo de contenido'} htmlFor={'type'} />
              <div className="relative">
                {<select
                  name='type'
                  onChange={handleInputChange}
                  value={currentMedia.type}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="media-type">
                  {typeValues
                    .filter(type => type.type === currentTypeMedia)
                    .map(type => <option key={`type-${type.label}`}
                      value={type.value}>{type.label}</option>)}
                </select>}
                <div
                  className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="w-full px-3 mb-6 md:mb-0">
              <LabelComponent label={'Identificador'} htmlFor={'identifier'} />
              <input
                readOnly={true}
                value={currentMedia.identifier}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="media-identifier" type="text" placeholder="VID_0001" />

              <ErrorLabelComponent
                shouldView={!currentMedia.identifier ||
                  currentMedia.identifier.trim().length === 0}
                text={'Escoge una opción válida'}
              />
            </div>
          </div>

          <div className='flex flex-col md:flex-row'>
            <div className="w-full px-3 mb-6 md:mb-0">
              <LabelComponent label={'Autor o productora'} htmlFor={'author'}
                required />
              <AutorInput
                rawAuthor={currentMedia.author}
                availableAuthors={authors}
                onChange={handleAuthorChange} />
              <ErrorLabelComponent
                shouldView={!currentMedia.author}
                text={'Autor o Productora es obligatorio'}
              />
            </div>

            <div className="w-full px-3 mb-6 md:mb-0">
              <LabelComponent label={'Disponible para'}
                htmlFor={'media-availability'} />
              <div className="relative">
                <select
                  name='access_role'
                  onChange={handleInputChange}
                  value={currentMedia.access_role}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="media-type">
                  {roles.map(type => <option key={`access_role-${type.id}`}
                    value={type.id}>{type.name}</option>)}
                </select>
                <div
                  className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-wrap'>
            <div className="w-full px-3 mb-6 md:mb-0">
              <LabelComponent label={'Titulo'}
                htmlFor={'title'}
                required />
              <input
                name='title'
                onChange={handleInputChange}
                value={currentMedia.title}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="media-title" type="text" placeholder="Ingrese título"
              />
              <ErrorLabelComponent
                shouldView={!currentMedia.title ||
                  currentMedia.title.trim().length === 0}
                text={'Titulo es obligatorio.'}
              />
            </div>
          </div>

          <div className="flex flex-wrap">
            <div className="w-full px-3">
              <LabelComponent label={'Reseña'}
                htmlFor={'description'}
              />
              <textarea
                className="no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-auto resize-y"
                id="media-description"
                name='description'
                onChange={handleInputChange}
                value={currentMedia.description} />
            </div>
          </div>


          <div className='flex flex-col md:flex-row'>
            <div className="w-full px-3 mb-6 md:mb-0">
              <LabelComponent label={'Región'}
                htmlFor={'region'}
              />
              <div className="relative">
                {<select
                  name='location'
                  onChange={handleInputChange}
                  value={currentMedia?.location?.id}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="media-type">
                  {locations
                    .map(location => <option key={`location-${location.id}`}
                      value={location.id}>{location.name}</option>)}
                </select>}
                <div
                  className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="w-full px-3 mb-6 md:mb-0">
              <LabelComponent label={'Lugar, Localidad o hito'}
                htmlFor={'place'}
              />
              <input
                name="place"
                value={currentMedia.place}
                onChange={handleInputChange}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="media-place" type="text" />
            </div>
          </div>

          <div className="flex flex-wrap">
            <div className="w-full px-3">
              <LabelComponent label={'Etiquetas'}
                htmlFor={'tags'}
              />
              <TagInput rawTags={currentMedia.tags} availableTags={tags}
                name='tags'
                onChange={handleTagsChange} />
            </div>
          </div>

          <div className='pt-4'>
            <div className='my-4 text-sm px-4'>
              <label htmlFor="media-persons"
                className="flex items-center cursor-pointer">
                <div className="relative">
                  <input id="media-persons" type="checkbox" className="hidden"
                    name={'hasPerson'}
                    checked={currentMedia.hasPerson}
                    onChange={handleInputChange} />
                  <div
                    className="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner" />
                  <div
                    className="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0" />
                </div>
                <div className="ml-3">¿Hay personas presentes y reconocibles en
                la imagen o video?
                </div>
              </label>
            </div>

            <div className='my-4 text-sm px-4'>
              <label htmlFor="media-persons-authorization"
                className="flex items-center cursor-pointer">
                <div className="relative">
                  <input id="media-persons-authorization" type="checkbox"
                    className="hidden"
                    name={'hasPersonAuthorization'}
                    checked={currentMedia.hasPersonAuthorization}
                    onChange={handleInputChange} />
                  <div
                    className="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner" />
                  <div
                    className="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0" />
                </div>
                <div className="ml-3">¿Existe la autorización de la persona?
                </div>
              </label>
            </div>

            <div className='my-4 text-sm px-4'>
              <label htmlFor="media-privileges"
                className="flex items-center cursor-pointer">
                <div className="relative">
                  <input id="media-privileges" type="checkbox"
                    className="hidden"
                    name='hasExistingRights'
                    checked={currentMedia.hasExistingRights}
                    onChange={handleInputChange} />
                  <div
                    className="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner" />
                  <div
                    className="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0" />
                </div>
                <div className="ml-3">¿Propiedad intelectual regularizada en favor de la Fundación.?</div>
              </label>
            </div>

            <div className='my-4 text-sm px-4'>
              <label htmlFor="media-publish"
                className="flex items-center cursor-pointer">
                <div className="relative">
                  <input id="media-publish" type="checkbox" 
                  className="hidden"
                    name='published'
                    checked={currentMedia.published}
                    onChange={handleInputChange} />
                  <div
                    className="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner" />
                  <div
                    className="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0" />
                </div>
                <div className="ml-3">¿Publicar en producción?</div>
              </label>
            </div>
          </div>

          <div className='mt-6 px-2'>
            <button
              className='bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded inline-flex items-center'>
              <svg className="w-8 pr-2" fill="none" strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
              <span>Guardar y avanzar</span>
            </button>
          </div>
        </form>

        {<Summary onPrev={eventPreviousMedia}
          onNext={eventNextMedia}
        />}
      </section>
    </div>
  )
}

export default MediaForm
