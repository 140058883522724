import { useContext, useState } from 'react'
import { MediaContext } from '../contexts/MediaContext'
import useRequest from './useRequest'
import { useAuth } from './useAuth'

const useMedia = () => {
  return useContext(MediaContext)
}

const useMediaProvider = () => {
  const [media, setMedia] = useState({})
  const request = useRequest()
  const auth = useAuth()

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token}`,
    },
  }
  const search = async ({
    terms = { },
    sort = { publishedAt: 'desc' },
    pagination = {
      from: 1,
      limit: 40,
    },
  }) => {
    const search = { terms, sort, pagination }
    const { data, headers } = await request.post(`/medias/search/by`, search,
      config)
    return { data,headers }
  }

  const fetchMediaById = async (id) => {
    const { data } = await request.get(`/medias/${id}`, config)
    setMedia(data)
    return data
  }

  const fetchMediaToLabel = async () => {
    const { data } = await request.get(`/medias/pendingToLabel`, config)
    setMedia(data)
    return data
  }

  const persistMedia = async (mediaToPersist) => {
    await request.patch(`/medias/${mediaToPersist.id}`, {
      type: mediaToPersist.type,
      author: mediaToPersist.author,
      title: mediaToPersist.title,
      description: mediaToPersist.description,
      tags: mediaToPersist.tags.split(','),
      hasPerson: mediaToPersist.hasPerson,
      hasPersonAuthorization: mediaToPersist.hasPersonAuthorization,
      hasExistingRights: mediaToPersist.hasExistingRights,
      publish: mediaToPersist.published,
      location: mediaToPersist.location?.id || mediaToPersist.location,
      access_role: mediaToPersist.access_role,
      place: mediaToPersist.place,
    }, config)

    const { data: next } = await request.post(
      `/medias/${mediaToPersist.id}/finishLabeling`, {}, config)

    setMedia(next)

    return next
  }

  const navigateToPrevious = async () => {
    const { data } = await request.get(
      `/medias/pendingToLabel?current=${media.identifier}&navigation=prev`,
      config)
    if (data) {
      setMedia(data)
    }
    return data
  }

  const navigateToNext = async () => {
    const { data } = await request.get(
      `/medias/pendingToLabel?current=${media.identifier}&navigation=next`,
      config)
    if (data) {
      setMedia(data)
    }
    return data
  }

  return {
    media,
    fetchMediaToLabel,
    fetchMediaById,
    persistMedia,
    search,
    navigateToNext,
    navigateToPrevious,
  }
}

export { useMedia, useMediaProvider }
