import React from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom'
import ProcessLabelPage from './components/LabelPage/ProcessLabelPage'
import Layout from './components/Layout'
import LayoutPage from './components/LayoutPage'
import Login from './components/Login/Login'
import { useAuth } from './hooks/useAuth'

const Routes = () => {
  const auth = useAuth()
  const location = useLocation();
  const isAuthenticated = auth.isAuthenticated
  return (<BrowserRouter>
      <Switch>
        <Route path="/medias/:id">
          {isAuthenticated ? <LayoutPage/> : <Redirect to="/login" from="/medias/:id"/>}
        </Route>
        <Route path="/login">
          {isAuthenticated ? <Redirect to={location.pathname || '/'}/> : <Login/>}
        </Route>
        <Route path="/proceso-etiquetado">
          {isAuthenticated ? <ProcessLabelPage/> : <Redirect to="/login" from="/proceso-etiquetado"/>}
        </Route>
        <Route path="/">
          {isAuthenticated ? <Layout/> : <Redirect to="/login" from="/"/>}
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
