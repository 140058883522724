import React from 'react'
import LabelPage from './LabelPage/LabelPage'

const LayoutPage = () => {
  return (
    <section className='flex flex-col p-4 md:p-0 md:w-5/6 m-auto'>
      <LabelPage/>
    </section>
  )
}

export default LayoutPage
