import React from 'react'

const LabelComponent = ({ label, htmlFor, required = false }) => {
  return (<label
    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
    htmlFor={htmlFor}>{label} {required && (<span
    className="text-red-500 text-xs italic">(*)</span>)}</label>)
}

export default LabelComponent
