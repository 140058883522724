import { useEffect, useState } from "react";
import useRequest from "./useRequest";
import { useAuth } from "./useAuth";

const useSummary = () => {
  const auth = useAuth()
  const request = useRequest();
  const [totalLabeled, setTotalLabeled] = useState(0)
  const [totalPending, setTotalPending] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const fetchSummary = async () => {
      const { data } = await request.get('/medias/summaryLabeling', {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setTotalLabeled(data.labeled)
      setTotalPending(data.pending)
      setLoading(false)
    }
    fetchSummary()
  }, [])

  return [{ totalLabeled, totalPending, loading }]
}

export default useSummary
