import React from 'react'
import { useAuth } from './hooks/useAuth'
import Header from './components/Header.'
import { ProvideAuth } from './contexts/AuthContext'
import ErrorComponent from './components/Error/ErrorComponent'
import { MediaContextProvider } from './contexts/MediaContext'
import Routes from './Routes'
import { ProviderError } from './contexts/ErrorContext'
import {BrowserRouter} from 'react-router-dom'
function App () {
  return (
    <BrowserRouter>
      <ProviderError>
        <ProvideAuth>
          <MediaContextProvider>
            <div className="App font-sans bg-gray-200 min-h-screen mx-auto">
              <Header/>
              <Routes/>
            </div>
          </MediaContextProvider>
        </ProvideAuth>
        <ErrorComponent/>
      </ProviderError>
      </BrowserRouter>

  )

}

export default App
