import React from 'react'
import useSummary from '../../hooks/useSummary'

const Summary = ({ onPrev, onNext }) => {
  const [{ totalPending, totalLabeled }] = useSummary()
  return <>
    {<div className='flex flex-col my-4 p-2 rounded-lg bg-white'>
      <div className='flex justify-between items-center p-2 md:hidden'>
        <div className='flex flex-col justify-center items-center'>
          <span className='text-xs text-gray-600'>Etiquetados</span>
          <span className='font-bold text-teal-600'>{totalLabeled}</span>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <span className='text-xs text-gray-600'>Pendientes</span>
          <span className='font-bold text-teal-600'>{totalPending}</span>
        </div>
      </div>
      <div className='flex justify-between items-center'>
        {onPrev && <button onClick={onPrev}
                           className='bg-teal-500 hover:bg-teal-500 text-white font-bold py-2 px-4 rounded inline-flex items-center'
                           href="/">Anterior
        </button>
        }

        <div className='md:flex flex-col justify-center items-center'>
          <span className='text-xs text-gray-600'>Etiquetados</span>
          <span className='font-bold text-teal-600'>{totalLabeled}</span>
        </div>
        <div className='md:flex flex-col justify-center items-center'>
          <span className='text-xs text-gray-600'>Pendientes</span>
          <span className='font-bold text-teal-600'>{totalPending}</span>
        </div>

        {onNext && <button onClick={onNext}
                           className='bg-teal-500 hover:bg-teal-500 text-white font-bold py-2 px-4 rounded inline-flex items-center'
                           href="/">Siguiente
        </button>}
      </div>
    </div>}
  </>
}

export default Summary
