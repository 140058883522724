import { useEffect, useState } from "react";
import useRequest from './useRequest';

const useTags = () => {
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)
  const request = useRequest();
  const fetchTags = async () => {
    const { data: tags } = await request.get('/tags?size=9');
    const tagsMap = tags.map(tag => {
      return { value: tag.name, label: tag.name }
    })
    setTags(tagsMap)
    setLoading(false)
  }

  const addTag = async (tagName) => {
    try {
      await request.post('/tags', { name: tagName });
      const newTags = [...tags, { value: tagName, label: tagName }]
      setTags(newTags);
    } catch (exception) {
    }

  }
  useEffect(() => {
    setLoading(true)
    fetchTags()
  }, [])

  return [{ tags, loading, fetchTags, addTag }]
}

export default useTags;
