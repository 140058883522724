const mediaTypes = {
  photo: 'Foto',
  video: 'Video',
  footage: 'Footage',
}

const mediaTypesSelect = [
  { value: 'all', label: 'Todos', type: 'all' },
  { value: 'video', label: 'Video', type: 'video' },
  { value: 'footage', label: 'Footage', type: 'video' },
  { value: 'photo', label: 'Foto', type: 'image' },
]

const mediaStatusLabeled = [
  { value: 'all', label: 'Todos' },
  { value: 'labeled', label: 'Etiquetado' },
  { value: 'unlabeled', label: 'Sin Etiquetar' },
]

const optionsForBooleans = [
  { value: "all", label: 'Todos' },
  { value: "null", label: 'Sin Valor' },
  { value: "true", label: 'Si' },
  { value: "false", label: 'No' },
]
export { mediaTypes, mediaTypesSelect, mediaStatusLabeled, optionsForBooleans }
