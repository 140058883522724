import React from 'react'

/**
 *
 * @param name
 * @param id
 * @param onChange
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
const InputComponent = ({ type, name, id, onChange, value,placeholder = '' }) => {
  return (<input
    type={type}
    name={name}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    className="block appearance-none w-full bg-gray-200 border border-gray-200 hover:border-gray-700 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
    id={id}>
  </input>)
}

export default InputComponent
