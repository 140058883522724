import React from "react";

const MediaAttribute = ({name, attr}) => {
  return <>
    <div className='flex flex-row text-xs mx-1 my-2'>
      <div className="p-0">
        <div className="inline-flex items-center bg-white leading-none text-teal-600 p-1 rounded-full text-teal">
          <span
            className="inline-flex bg-teal-500 text-white rounded-full h-6 px-2 justify-center items-center">
            {name}
          </span>
          <span className="inline-flex px-2">{attr}</span>
        </div>
      </div>
    </div>
  </>
}

export default MediaAttribute
