import React, {createContext} from "react";
import {useProvideAuth} from "../hooks/useAuth";

const authContext = createContext('');

const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export {authContext, ProvideAuth}
