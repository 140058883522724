import React, { useEffect, useState } from "react";
import { useError } from "../../hooks/useError";

const ErrorComponent = () => {
    const error = useError();
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        if (error.message) {
            setIsVisible(true);
            setTimeout(() => {
                setIsVisible(false);
                error.cleanMessage();
            }, 4000);
        }
    }, [error.message])

    if (!isVisible && !error.message)
        return null;

    return (
        <div>
            <div className="alert-footer w-full fixed bottom-0">
                <input type="checkbox" className="hidden" id="footeralert" />

                <div className="flex items-center justify-between w-full p-2 bg-red-500 shadow text-white">
                    {error.message}
                    <label className="close cursor-pointer" title="close" htmlFor="footeralert">
                        <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                    </label>
                </div>
            </div>
        </div>
    )
};

export default ErrorComponent;