import axios from 'axios';
import useLocalStorage from './useLocalStorage';
import { useError } from './useError';
import errors from '../helper/error.message.helper';
import { useHistory } from 'react-router-dom';
const useRequest = () => {
  const errorHook = useError();
  const history = useHistory();
  const [token, setToken] = useLocalStorage('token', '');
  const request = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json', }
  });
  request.interceptors.request.use((config) => {

    if (token && token.length > 0) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
    error => {
      return Promise.reject(error);
    },
  );
  request.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      let { response } = error;
      if (response === undefined) {
        errorHook.changeMessage('Error de servidor');
        return Promise.reject(error);
      }

      let { data = {}, status = 400 } = response;

      let messageError = errors[status] || (data?.message || 'Error Servidor');
      if(status === 401 || status === 403){
        setToken('');
        history.push('/');
      }
      errorHook.changeMessage(messageError);
      return Promise.reject(error);
    },
  );

  return request;
};

export default useRequest;