import { useContext, useState, useEffect } from "react";
import { ErrorContext } from "../contexts/ErrorContext";

const useError = () => {
  return useContext(ErrorContext)
}

const useProvideError = () => {
  const [message, setMessage] = useState(undefined);
  const cleanMessage = () => setMessage(undefined);

  useEffect(() => {
    cleanMessage()
  }, []);


  return { changeMessage: setMessage, message, cleanMessage }
}

export { useError, useProvideError }
