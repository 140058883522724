import { useEffect, useState } from "react";
import useRequest from './useRequest';

const useLocation = () => {
  const [locations, setLocations] = useState([])
  const [loading, setLoading] = useState(false)
  const request = useRequest();
  const fetchRegions = async () => {
    const { data } = await request.get('/locations', { params: { type: 'region' } });
    setLocations(data)
    setLoading(false)
  }
  useEffect(() => {
    setLoading(true)
    fetchRegions()
  }, [])

  return [{ locations, loading, fetchRegions }]
}

export default useLocation;
