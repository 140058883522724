import React, { createContext } from "react";
import { useProvideError } from "../hooks/useError";

const ErrorContext = createContext('');

const ProviderError = ({ children }) => {
  const error = useProvideError();
  return <ErrorContext.Provider value={error}>{children}</ErrorContext.Provider>;
}

export { ErrorContext, ProviderError, useProvideError }
