import React, { useEffect, useState } from 'react'
import { useError } from '../../hooks/useError'
import MediaForm from './MediaForm'
import './styles.css'
import { useMedia } from '../../hooks/useMedia'
import { DisappearedLoading } from 'react-loadingg'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

const NAVIGATE_NEXT = 'next'
const NAVIGATE_PREVIOUS = 'previous'
const ProcessLabelPage = () => {
  const [loading, setLoading] = useState(true)
  const [isVisiblePrevious, setIsVisiblePrevious] = useState(true)
  const [isVisibleNext, setIsVisibleNext] = useState(true)
  const [shouldLoadData, setShouldLoadData] = useState(false)
  const mediaHook = useMedia()
  const errorHook = useError()
  const location = useLocation()
  const history = useHistory()
  const [currentMedia, setCurrentMedia] = useState(undefined)

  const calculateIdentifier = (identifier, type) => {
    let prefix
    switch (type) {
      case 'video':
        prefix = 'VID_'
        break
      case 'footage':
        prefix = 'FOOT_'
        break
      case 'photo':
        prefix = 'IMG_'
        break
      default:
        prefix = ''
    }
    return prefix + identifier.toString().padStart(7, '0')
  }

  const navigatePrevious = (event) => {
    event.preventDefault()
    setLoading(true)
    navigateNextOrPrevious(NAVIGATE_PREVIOUS)
  }

  const navigateNextOrPrevious = (navigate) => {
    setIsVisibleNext(true)
    setIsVisiblePrevious(true)
    let searchQueryString = queryString.parse(location.search)
    searchQueryString.currentItem = parseInt(searchQueryString.currentItem)
    if (navigate === NAVIGATE_NEXT)
      searchQueryString.currentItem++

    if (navigate === NAVIGATE_PREVIOUS) {
      if (searchQueryString.currentItem <= 1) {
        searchQueryString.currentItem = 1
        setIsVisiblePrevious(false)
      }
    }

    let queryStringify = queryString.stringify(searchQueryString)
    history.push(`/proceso-etiquetado?${queryStringify}`)
    setShouldLoadData(true)
  }

  const navigateNext = (event) => {
    event.preventDefault()
    setLoading(true)
    navigateNextOrPrevious(NAVIGATE_NEXT)
  }

  const saveMedia = (media) => {
    setLoading(true)
    mediaHook.persistMedia(media).then(() => {
      navigateNextOrPrevious(NAVIGATE_NEXT)
    })
  }
  const fetchCurrentMedia = (m) => {
    const identifier = calculateIdentifier(m.identifier, m.type)
    setCurrentMedia({ ...m, identifier })
    setLoading(false)
    setShouldLoadData(false)
  }

  async function fetchMedia () {
    let { currentItem = 1, ...searchQueryString } = queryString.parse(
      location.search)
    const search = {
      terms: searchQueryString,
      pagination: {
        from: currentItem,
        limit: 1,
      },
    }
    const { data: response, headers } = await mediaHook.search(search)

    let totalItem = parseInt(headers['x-pagination-total-count'])
    if (currentItem >= totalItem)
      setIsVisibleNext(false)

    if (currentItem === 1)
      setIsVisiblePrevious(false)

    const [data] = response
    if (!data) {
      errorHook.changeMessage('No hay medias')
      setTimeout(() => {
        history.goBack()
      }, 3000)
      return
    }
    mediaHook.fetchMediaById(data.id).then(fetchCurrentMedia)
  }

  useEffect(() => {
    setShouldLoadData(true)
  }, [])

  useEffect(() => {
    if (shouldLoadData) {
      setLoading(true)
      fetchMedia()
    }
  }, [shouldLoadData])

  if (loading) {
    return (
      <div>
        <DisappearedLoading/>
      </div>
    )
  }

  return (
    <section className='flex flex-col p-4 md:p-0 md:w-5/6 m-auto'>
      <MediaForm
        media={currentMedia}
        navigateNextMedia={navigateNext}
        navigatePreviousMedia={navigatePrevious}
        saveMedia={saveMedia}/>
    </section>)
}

export default ProcessLabelPage
