import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import logo from '../assets/img/logo.svg'
import { useAuth } from '../hooks/useAuth'

const Header = () => {
  const auth = useAuth()
  const [isVisible,setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(!!auth.token);
  }, [auth.token])

  const signOut = () => {
    auth.signOut();
  }
  if(!isVisible)
    return null;

  return (
    <header className='pb-6'>
      <div
        className='flex flex-row justify-between w-5/6 m-auto items-center p-4'>
        <img className='w-16' alt='logo' src={logo}/>

        <div className='flex items-center'>
            <span
              className='font-bold px-4 py-2 cursor-default'>{auth?.me?.name?.given} {auth?.me?.name?.firstLastName}</span>
          <button onClick={signOut}>
            <svg className='w-6 text-teal-500 hover:text-teal-600'
                 fill="none"
                 strokeLinecap="round" strokeLinejoin="round"
                 strokeWidth="2"
                 stroke="currentColor" viewBox="0 0 24 24">
              <path
                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"/>
            </svg>
          </button>
        </div>
      </div>
    </header>
  )
}
export default Header
