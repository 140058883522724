import { format, parseISO } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'
import { mediaTypes } from '../../helper/medias.helper'
import { IconButton, Icon, Table, Popover, Whisper } from 'rsuite';
const booleanToString = (value) => {
  if (true === value)
    return 'Si'

  if (false === value)
    return 'No'

  return 'Sin Valor'
}
const ActionCell = ({ rowData, dataKey, ...props }) => (
  <Table.Cell {...props}>
    <Link to={`/medias/${rowData.id}`}>
      <IconButton icon={<Icon icon="eye" appearance="default" />}>Ver</IconButton>
    </Link>
  </Table.Cell>
);

const AuthorCell = ({ rowData, dataKey, ...props }) => (
  <Table.Cell {...props}>
    {rowData?.author?.name}
  </Table.Cell>
);

const MediaTypeCell = ({ rowData, dataKey, ...props }) => (
  <Table.Cell {...props}>
    {mediaTypes[rowData.type] ?? 'N/N'}
  </Table.Cell>
);

const BooleanCell = ({ rowData, dataKey, ...props }) => (
  <Table.Cell {...props}>
    {booleanToString(rowData[dataKey])}
  </Table.Cell>
);
const DateCell = ({ rowData, dataKey, ...props }) => (
  <Table.Cell {...props}>
    {format(parseISO(rowData[dataKey]), 'dd/MM/yyyy HH:mm:ss ')}
  </Table.Cell>
);

const TitleCell = ({ rowData, dataKey, ...props }) => {
  const speaker = (
    <Popover title="Datos del Media">
      <p>
        <b>Id:</b> {rowData.id}
      </p>
      <p>
        <b>Titulo:</b> {rowData.title}
      </p>
      <p>
        <b>Tipo:</b>     {mediaTypes[rowData.type] ?? 'N/N'}
      </p>
      <p>
        <b>Fecha Creación:</b>         {format(parseISO(rowData.createdAt), 'dd/MM/yyyy HH:mm:ss ')}
      </p>
      <p>
        <b>Autor:</b>         {rowData?.author?.name}
      </p>
      <p>
        <b>Autorización Persona:</b>   {booleanToString(rowData.hasPersonAuthorization)}
      </p>
      <p>
        <b>Persona Protagonica:</b>   {booleanToString(rowData.hasPerson)}
      </p>
    </Popover>
  );

  return (
    <Table.Cell {...props}>
      <Whisper placement="top" speaker={speaker} enterable>
        <a>{rowData[dataKey]}</a>
      </Whisper>
    </Table.Cell>
  );
};

export default {
  ActionCell,
  AuthorCell,
  MediaTypeCell,
  BooleanCell,
  DateCell,
  TitleCell
}