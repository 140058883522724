const selectStyles = {
  multiValue: (styles, {data}) => {
    return {
      ...styles,
      '--bg-opacity': 1,
      backgroundColor: 'rgba(56, 178, 172, var(--bg-opacity))',
      borderRadius: '0.25rem',
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: 'white',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
  control: styles => ({
    ...styles,
    '--bg-opacity': 1,
    '--border-opacity': 1,
    background: "rgba(237, 242, 247, var(--bg-opacity))",
    borderColor: "rgba(237, 242, 247, var(--border-opacity))",
    appearance: 'none',
    boxShadow: 'none !important',
    ':focus': {
      ...styles[':focus'],
      backgroundColor: 'white',
      outline: 'none',
      borderColor: "rgba(237, 242, 247, var(--border-opacity))",
    },
    ':hover': {
      ...styles[':hover'],
      boxShadow: 'none !important',
      borderColor: "rgba(237, 242, 247, var(--border-opacity))",
    },
  }),
}

export default selectStyles;
