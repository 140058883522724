import React, { useEffect, useState } from "react";
import useInput from "../../hooks/useInput";
import { useAuth } from "../../hooks/useAuth";
import logo from '../../assets/img/logo.svg';
import { useHistory } from 'react-router-dom'

const Login = () => {
  const auth = useAuth()
  const history = useHistory()
  const username = useInput('')
  const password = useInput('')
  const [hasError, setHasError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault()

    auth.signIn(username.value, password.value).then((res) => {
      history.push('/');
    })
  }

  useEffect(
    () => {
      if (auth.error?.data)
        setHasError(true);
    }
    , [auth.error]);

  const showError = () => {
    if (hasError)
      return (<div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
      <p className="font-bold">¡Hubo un error!</p>
      <p>Las credenciales ingresadas no son correctas.</p>
    </div>)

      return null;
  }

  return (<>
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full py-12 px-6">
        <img className="mx-auto h-16 w-auto" src={logo} alt="" />
        <p className="mt-6 text-sm leading-5 text-center text-gray-900">Entra con tu cuenta</p>

        {showError()}
        <form className="mt-5" onSubmit={onSubmit}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md">
            <div>
              <input {...username} aria-label="Rut" name="username" type="email" required={true}
                className="border-gray-300 placeholder-gray-500 mb-2 appearance-none rounded-md relative block w-full px-3 py-2 border text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Email" />
            </div>
            <div className=" relative">
              <input {...password} aria-label="Password" name="password" type="password" required={true}
                className="border-gray-300 placeholder-gray-500 appearance-none rounded-md relative block w-full px-3 py-2 border text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Contraseña" />
            </div>
          </div>

          <div className="mt-5">
            <button
              type="submit"
              className="relative block w-full py-2 px-3 border border-transparent rounded-md text-white font-semibold bg-teal-600 hover:bg-teal-500 focus:bg-teal-700 focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5">
              <span className="absolute left-0 inset-y pl-3">
                <svg className="h-5 w-5 text-teal-300" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule={"evenodd"}
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule={"evenodd"} />
                </svg>
              </span>
                  Entrar
                </button>
          </div>
        </form>
      </div>
    </div>
  </>)
}

export default Login
