import React, {createContext} from "react";
import { useMediaProvider} from "../hooks/useMedia";

const MediaContext = createContext({})

const MediaContextProvider = ({children}) => {
  const media = useMediaProvider()
  return (
    <MediaContext.Provider value={media}>
      {children}
    </MediaContext.Provider>
  )
}

export {MediaContext, MediaContextProvider};
