import React, { useContext, useState, useEffect } from "react";
import useRequest from './useRequest'
import useLocalStorage from "./useLocalStorage";
import { authContext } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { useError } from "./useError";

const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const request = useRequest();
  const [token, setToken] = useLocalStorage('token', '');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [me, setMe] = useState(undefined);
  const error = useError();

  const validatePermissionUser = (userName) => {
    let permissions = userName?.roles?.permissions;
    if (Array.isArray(permissions) && !permissions.includes('access_etiquetado')) {
      signOut();
      error.changeMessage('No tienes permisos suficientes');
      return;
    }
  }
  useEffect(() => {
    if (token)
      getMe().then(validatePermissionUser);

    setIsAuthenticated(!!token);

  }, [token]);


  const signIn = async (email, password) => {
    try {
      const { data } = await request.post('/auth/token', { email, password })
      setToken(data.token)
      error.changeMessage(undefined);
      return data.token
    } catch (e) {
      if(e.response?.data?.message)
       error.changeMessage(e.response?.data?.message);
    }
  };

  const signOut = () => {
    setToken("")
    //history.push("/");
  };

  const getMe = async () => {
    const { data } = await request.get('/me', { headers: { Authorization: `Bearer ${token}` } })

    setMe(data);
    return data;
  };


  return {
    token,
    signIn,
    signOut,
    getMe,
    isAuthenticated,
    error,
    me
  };
}

export { useAuth, useProvideAuth }
