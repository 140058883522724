import React, { useEffect, useState } from 'react'
import MediaForm from './MediaForm'
import './styles.css'
import { useMedia } from '../../hooks/useMedia'
import { DisappearedLoading } from 'react-loadingg'
import { useParams, useHistory } from 'react-router-dom'

const LabelPage = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const mediaHook = useMedia()
  const history = useHistory();
  const [currentMedia, setCurrentMedia] = useState(undefined)

  const calculateIdentifier = (identifier, type) => {
    let prefix
    switch (type) {
      case 'video':
        prefix = 'VID_'
        break
      case 'footage':
        prefix = 'FOOT_'
        break
      case 'photo':
        prefix = 'IMG_'
        break
      default:
        prefix = ''
    }
    return prefix + identifier.toString().padStart(7, '0')
  }

  const navigatePrevious = (event) => {
    event.preventDefault()
    setLoading(true)
    mediaHook.navigateToPrevious().then(m => {
      !!m && fetchCurrentMedia(m)
      setLoading(false)
    })
  }

  const navigateNext = (event) => {
    event.preventDefault()
    setLoading(true)
    mediaHook.navigateToNext().then(m => {
      !!m && fetchCurrentMedia(m)
      setLoading(false)
    })
  }

  const saveMedia = (media) => {
    mediaHook.persistMedia(media).then(m => {
      !!m && fetchCurrentMedia(m)
      setLoading(false)
    })
  }
  const fetchCurrentMedia = (m) => {
    history.push(`/medias/${m.id}`)
    const identifier = calculateIdentifier(m.identifier, m.type)
    setCurrentMedia({ ...m, identifier })
    setLoading(false)
  }
  useEffect(() => {
    setLoading(true)
    if (id) {
      mediaHook.fetchMediaById(id).then(fetchCurrentMedia)
      return
    }
    mediaHook.fetchMediaToLabel().then(fetchCurrentMedia)

  }, [])

  if (loading) {
    return (
      <div>
        <DisappearedLoading/>
      </div>
    )
  }

  return (<MediaForm
    media={currentMedia}
    navigateNextMedia={navigateNext}
    navigatePreviousMedia={navigatePrevious}
    saveMedia={saveMedia}/>)
}

export default LabelPage
