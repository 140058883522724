import React from 'react'

/**
 *
 * @param [{label: string, value:string }]options
 * @param name
 * @param id
 * @param onChange
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
const SelectComponent = ({ options, name, id, onChange, value,placeholder }) => {
  return (<select
    name={name}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    className="block appearance-none w-full bg-gray-200 border border-gray-200 hover:border-gray-700 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
    id={id}>
    {options
      .map((option, index) => <option key={`${id}-${index}`}
                                      value={option.value}>{option.label}</option>)
    }</select>)
}

export default SelectComponent
