import React from 'react'
import { DateRangePicker } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const { afterToday } = DateRangePicker

const DatePickerComponent = ({ onChange, disabledDate = afterToday() }) => {
  return (
    <DateRangePicker disabledDate={disabledDate}
                     onChange={onChange}/>
  )
}

export default DatePickerComponent

