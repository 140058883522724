import React from "react";

const PreviewImage = ({image}) => {
  return <>
    <svg className='absolute top-0 left-0 w-6 bg-teal-500 rounded-full' fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="white" viewBox="0 0 24 24">
      <path d="M5 13l4 4L19 7" />
    </svg>
    <p className='absolute font-bold text-white bg-gray-800 px-4 py-2 right-0 opacity-75'>Vista previa</p>
    <img className='w-full' src={image} alt="thumb1"/>
  </>
}

export default PreviewImage
