import { useEffect, useState } from "react";
import useRequest from './useRequest';

const useRol = () => {
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const request = useRequest();
  const fetchRoles = async () => {
    const { data } = await request.get('/roles');
    setRoles(data)
    setLoading(false)
  }
  useEffect(() => {
    setLoading(true)
    fetchRoles()
  }, [])

  return [{ roles, loading, fetchRoles }]
}

export default useRol;
