import React, { useEffect, useState } from 'react'
import Creatable from 'react-select/creatable'
import useAuthor from '../../hooks/useAuthor'
import selectStyles from './Styles/creatable.style'

const AutorInput = ({ rawAuthor, onChange, availableAuthors }) => {
  const [authorMedia, setAuthorsMedias] = useState(undefined)
  const [{ addAuthor }] = useAuthor()

  useEffect(() => {
    if (rawAuthor) {
      setAuthorsMedias({
        label: rawAuthor.name,
        value: rawAuthor.id,
      })
      return
    }
    setAuthorsMedias(undefined)
  }, [rawAuthor])

  const onCreateOption = async (author) => {
    try {
      const { id } = await addAuthor(author)
      const newAuthors = [...authorMedia, { value: author, label: id }]
      onChange(newAuthors)
    } catch (e) {
    }
  }

  return (
    <div>
      <Creatable
        styles={selectStyles}
        options={availableAuthors}
        onChange={onChange}
        onCreateOption={onCreateOption}
        formatCreateLabel={(val) => `Crear ${val}`}
        value={authorMedia}/>
    </div>
  )
}

export default AutorInput
