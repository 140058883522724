import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InputComponent from './Form/InputComponent'
import { useMedia } from '../hooks/useMedia'
import {
  mediaTypesSelect,
  mediaStatusLabeled,
  optionsForBooleans,
} from '../helper/medias.helper'
import LabelComponent from './Form/LabelComponent'
import DatePickerComponent from './Form/DateRangePicker'
import AutorInput from './Form/AutorInput'
import useAuthor from '../hooks/useAuthor'
import Select from './Form/Select'
import moment from 'moment'
import { Icon, Table, Modal, Button } from 'rsuite'
import TableMediasHelper from './TableMedia/table.medias.helper'

const Layout = () => {
  const [{ authors, fetchAuthors }] = useAuthor()
  const [search, setSearch] = useState({})
  const [rows, setRows] = useState([])
  const [totalMedias, setTotalMedias] = useState(0)
  const [isShow, setIsShow] = useState(false)
  const [displayLength, setDisplayLength] = useState(10)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [canLoadData, setCanLoadData] = useState(true)
  const media = useMedia()
  const history = useHistory()

  useEffect(() => {
    fetchAuthors().then()

  }, [])
  useEffect(() => {
    if (canLoadData) {
      setLoading(true)
      media.search({
        terms: search, pagination: {
          from: 0,
          limit: displayLength,
        },
      }).then((res) => {
        setRows(res.data)
        setPage(1);
        setTotalMedias(parseInt(res.headers['x-pagination-total-count']))
        setCanLoadData(false)
        setLoading(false)
      })
    }
  }, [canLoadData])

  useEffect(() => {
    setLoading(true)
    media.search({
      terms: search,
      pagination: {
        from: (page - 1) * displayLength,
        limit: displayLength,
      },
    }).then((res) => {
      setRows(res.data)
      setLoading(false)
    })
  }, [page, displayLength])

  const handleInputChange = event => {
    const { name, value, type, checked } = event.target
    if (value === 'all') {
      let searchCopy = Object.assign({}, search)
      delete searchCopy[name]

      setSearch(searchCopy)
      return
    }
    setSearch({
      ...search,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleDatePickerChange = dates => {
    const [createdAtStart, createdAtEnd] = dates

    setSearch({
      ...search,
      createdAtStart: moment(createdAtStart).format(),
      createdAtEnd: moment(createdAtEnd).format(),
    })
  }
  const goToProcessLabeled = () => {
    setIsShow(false)
    let searchParams = { currentItem: 1, ...search }
    let queryStringify = queryString.stringify(searchParams)
    history.push(`/proceso-etiquetado?${queryStringify}`)
  }
  const beginProcessLabeled = () => {
    media.search({ terms: search }).then((res) => {
      setTotalMedias(parseInt(res.headers['x-pagination-total-count']))
      setIsShow(true)
    })
  }
  return (
    <>
      <section className='flex flex-col p-4 md:p-0 md:w-5/6 m-auto mb-10'>
        <section className='bg-white pb-4 px-4 rounded-md w-full'>
          <div className="flex justify-between w-full pt-6 ">
            <p
              className="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2">Filtros</p>
          </div>
          <div className="grid grid-cols-5 mb-5">
            <div className="px-2">
              <LabelComponent htmlFor={'title'} label={'Titulo o tags'} />
              <InputComponent name={'search'}
                type={'text'}
                placeholder={'Ingrese titulo o tag'}
                onChange={handleInputChange} />
            </div>
            <div className="px-2">
              <LabelComponent htmlFor={'author'} label={'Autor o productora'} />
              <AutorInput
                onChange={(author) => setSearch((prevState) => {
                  return { ...prevState, 'author.name': author.label }
                })}
                rawAuthor={null}
                availableAuthors={authors}
              /></div>
            <div className="px-2">
              <LabelComponent htmlFor={'type'} label={'Tipo Material'} />
              <Select name={'type'}
                value={null}
                id={'type'}
                options={mediaTypesSelect}
                onChange={handleInputChange} />
            </div>
            <div className="px-2">
              <LabelComponent label={'Fecha Creación'} htmlFor={'createdAt'} />
              <DatePickerComponent onChange={handleDatePickerChange} />
            </div>
            <div className="px-2">
              <LabelComponent htmlFor={'labelingStatus'} label={'Estado'} />
              <Select name={'labelingStatus'} id={'labelingStatus'}
                onChange={handleInputChange}
                options={mediaStatusLabeled} />
            </div>
            <div className="px-2">
              <LabelComponent htmlFor={'hasPerson'}
                label={'Persona Protagonica'} />
              <Select name={'hasPerson'} id={'hasPerson'}
                onChange={handleInputChange}
                options={optionsForBooleans} />
            </div>
            <div className="px-2">
              <LabelComponent htmlFor={'hasPerson'}
                label={'Cesión de derecho'} />
              <Select name={'hasExistingRights'} id={'hasExistingRights'}
                onChange={handleInputChange}
                options={optionsForBooleans} />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/8">
              <button
                className="bg-teal-500 hover:bg-teal-500 text-white font-bold py-2 px-4 rounded inline-flex items-center mr-5"
                onClick={() => setCanLoadData(true)}>
                <svg className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path
                    d="M12.9056439,14.3198574 C11.5509601,15.3729184 9.84871145,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,9.84871145 15.3729184,11.5509601 14.3198574,12.9056439 L19.6568542,18.2426407 L18.2426407,19.6568542 L12.9056439,14.3198574 Z M8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 Z"
                    id="Combined-Shape" />
                </svg>
                <span>Buscar</span>
              </button>
            </div>
            <div className="w-1/3">
              <button
                className="bg-green-500 hover:bg-green-500 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                onClick={beginProcessLabeled}>
                <svg className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path
                    d="M0,10 L0,2 L2,0 L10,0 L20,10 L10,20 L0,10 Z M4.5,6 C5.32842712,6 6,5.32842712 6,4.5 C6,3.67157288 5.32842712,3 4.5,3 C3.67157288,3 3,3.67157288 3,4.5 C3,5.32842712 3.67157288,6 4.5,6 Z"
                    id="Combined-Shape" />
                </svg>
                <span>Comenzar Proceso Etiquetado</span>
              </button>
            </div>
          </div>
        </section>
      </section>
      <Modal backdrop="static" show={isShow} onHide={() => setIsShow(false)}
        size="xs">
        <Modal.Header>
          Confirmación de acción
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de comenzar el proceso de etiquetado?. Se han
          encontrado <strong>{totalMedias}</strong> medias.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={goToProcessLabeled} color="green">
            <Icon icon="check-circle" /> Comenzar
          </Button>
          <Button onClick={() => setIsShow(false)} color="red">
            <Icon icon="times-circle" /> Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <section className='flex flex-col p-4 md:p-0 md:w-5/6 m-auto'>
        <div className="flex justify-between w-full p-4 bg-white">
          <p
            className="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2">Listado
            de Medias</p>
        </div>
        <Table data={rows} loading={loading} autoHeight>
          <Table.Column width={300} align="center" fixed>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.Cell dataKey="id" />
          </Table.Column>

          <Table.Column width={100}>
            <Table.HeaderCell>Titulo</Table.HeaderCell>
            <TableMediasHelper.TitleCell dataKey="title" />
          </Table.Column>

          <Table.Column width={100}>
            <Table.HeaderCell>Tipo</Table.HeaderCell>
            <TableMediasHelper.MediaTypeCell />
          </Table.Column>

          <Table.Column width={200}>
            <Table.HeaderCell>Autor</Table.HeaderCell>
            <TableMediasHelper.AuthorCell />
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell>F. Creación</Table.HeaderCell>
            <TableMediasHelper.DateCell dataKey="createdAt" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Autorización Persona</Table.HeaderCell>
            <TableMediasHelper.BooleanCell dataKey="hasPersonAuthorization" />
          </Table.Column>
          <Table.Column width={150}>
            <Table.HeaderCell>Persona Protagonica</Table.HeaderCell>
            <TableMediasHelper.BooleanCell dataKey="hasPerson" />
          </Table.Column>
          <Table.Column width={200} fixed="right">
            <Table.HeaderCell>Acciones</Table.HeaderCell>
            <TableMediasHelper.ActionCell />
          </Table.Column>
        </Table>

        <Table.Pagination
          lengthMenu={[
            {
              value: 10,
              label: 10,
            },
            {
              value: 20,
              label: 20,
            },
            {
              value: 30,
              label: 30,
            },
          ]}
          total={totalMedias}
          activePage={page}
          displayLength={displayLength}
          onChangePage={setPage}
          onChangeLength={setDisplayLength}
        />
      </section>
    </>
  )
}

export default Layout
