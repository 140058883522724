import React, {useEffect, useState} from "react";
import Creatable from "react-select/creatable";
import selectStyles from './Styles/creatable.style'
import useTags from "../..//hooks/useTags";

const TagsInput = ({rawTags, onChange, availableTags}) => {
    const [mediaTags, setMediaTags] = useState([])
    const [{addTag}] = useTags();

    useEffect(() => {
        setMediaTags(!rawTags ? [] : rawTags.split(',').map(value => {
            return {value, label: value}
        }))
    }, [rawTags])

    const onCreateOption = async (tagName) => {
        try {
            await addTag(tagName);
            const newTags = [...mediaTags, {value: tagName, label: tagName}]
            onChange(newTags);
        } catch (e) {
        }
    }

    return (
        <div>
            <Creatable
                styles={selectStyles}
                isMulti
                options={availableTags}
                onChange={onChange}
                onCreateOption={onCreateOption}
                formatCreateLabel={(val) => `Crear ${val}`}
                value={mediaTags}/>
        </div>
    )
}

export default TagsInput
