import React, {useState} from "react";
import axios from 'axios';

const DownloadButton = ({mediaFile}) => {
  const [loading, setLoading] = useState(false)

  const onClickHandler = (event) => {
    event.preventDefault()

    if (loading) return;

    setLoading(true)
    axios.get(mediaFile, {
      responseType: 'blob'
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data],{type: response.data.type}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', mediaFile.split('/').pop());
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoading(false)
    })
  }

  return <>
    <a onClick={onClickHandler} rel='noopener noreferrer' href={mediaFile} target={'_blank'} download className='ml-1 bg-teal-500 hover:bg-teal-500 text-white font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer'>
      <svg className="w-4 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
        <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
      </svg>
      <span>Descargar</span>
    </a>
  </>
}

export default DownloadButton
